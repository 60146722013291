(function() {
  var config = angular.module('priorConfig', []);

  config.constant('apiEndpoint', 'https://kope-api-dev.priorhq.com');
  config.constant('appName', 'Kope Poke');
  config.constant('brandId', 1);
  config.constant('isDev', true);
  config.constant('apiVersion', 2);
  config.constant('facebookAppId', '165889182196136');
  config.constant('facebookPixelId', '');
  config.constant('gtagPropertyIds', ['UA-55500817-73']);
  config.constant('gtmContainerIds', []);
  config.constant('trackGtagConversions', []);
  config.constant('trackGATransactions', ['UA-55500817-3']);
  config.constant('trackGAViews', ['UA-55500817-3']);
  config.constant('currencyMultiplier', 100);
  config.constant('currencyFractionDigits', 2);
  config.constant('kushkiClientId', 'de015dde66054e41a5f66e519f4e2588');
  config.constant('kushkiTestEnvironment', true);
  config.constant('mangopayClientId', 'tgo-mangopay');
  config.constant('mangopayBaseUrl', 'https://api.sandbox.mangopay.com');
  config.constant('defaultLanguage', 'es-ES');
  config.constant('gaUseGtag', true);
})();
